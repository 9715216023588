import { RobotsEnum } from './types';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';

export function metaErrorPage () {
  const title = this.$route.meta.title || this.$route.meta.page === '404' ? this.$t('Not Found Page') : this.$t('Error Page');
  const description = this.$route.meta.description;
  const relRobots = relRobotsHandler(RobotsEnum.INDEX, config.xEnv.key);
  const meta = {
    title: title,
    meta: {
      ...relRobots,
      title: {
        name: 'title',
        content: title
      },
      description: {
        name: 'description',
        content: description
      }
    }
  };

  return mergeMeta(meta)
}
