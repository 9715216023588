<template>
  <div id="error">
    <MError
      :title="'500'"
      :subtitle="$t('This site is getting a tune-up')"
    />
  </div>
</template>

<script>
import MError from 'theme/components/molecules/m-error';
import { Logger } from '@vue-storefront/core/lib/logger';
import { metaErrorPage } from '../meta/errors';

export default {
  name: 'Error',
  components: {
    MError
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  asyncData ({ context, route }) {
    return new Promise((resolve) => {
      Logger.log('Calling asyncData for Error page ' + new Date())();
      if (context) {
        context.output.cacheTags.add(`error`);
        if (route.params.code) context.server.response?.status(route.params.code)
      }
      resolve();
    })
  },
  metaInfo: metaErrorPage
};
</script>
