<template>
  <div id="not-found">
    <MNotFound
      :title="$t('How unexpected!')"
      :subtitle="$t('Alas, there is no such page on the site. It was been removed, or perhaps never existed at all.')"
    />
  </div>
</template>

<script>
import MNotFound from 'theme/components/molecules/m-not-found';
import { Logger } from '@vue-storefront/core/lib/logger';
import { metaErrorPage } from '../meta/errors';
import { notFoundLogging } from 'theme/helpers/web-logging';

export default {
  name: 'NotFound',
  components: {
    MNotFound
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  asyncData ({ context }) {
    return new Promise((resolve) => {
      Logger.log('Calling asyncData for NotFound page ' + new Date())();
      if (context) {
        context.output.cacheTags.add(`error`);
        context.output.cacheTags.add(`error:404`);
        context.server.response?.status(404)
      }
      resolve();
    })
  },
  mounted () {
    notFoundLogging()
  },
  metaInfo: metaErrorPage
};
</script>
