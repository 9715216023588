<template>
  <div class="m-error error">
    <SfHeading
      class="error__message"
      :title="title"
      :subtitle="subtitle"
      :level="level"
    />
  </div>
</template>

<script>
import { SfHeading } from '@storefront-ui/vue';

export default {
  name: 'MError',
  components: {
    SfHeading
  },
  props: {
    level: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~theme/css/breakpoints';

.error {
  align-items: center;
  background-color: #c7eafd;
  background-image: url('/assets/error-mobile.jpg');
  background-position: bottom;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 80vh;

  @include for-desktop {
    background-image: url('/assets/error-desktop.jpg');
    background-position: center;
  }

  &__message {
    transform: translateY(-50%);

    --heading-title-color: var(--orange);
    --heading-title-font-size: 90px;
    --heading-title-font-weight: var(--font-bold);

    --heading-subtitle-color: var(--black);
    --heading-subtitle-font-size: var(--font-size-20);
    --heading-subtitle-font-weight: var(--font-medium);

    @include for-desktop {
      --heading-title-font-size: 110px;

      --heading-subtitle-font-size: var(--font-size-32);
    }
  }
}
</style>
