<template>
  <div class="m-not-found error">
    <div class="image-wrapper">
      <SfImage
        src="/assets/404.svg"
        class="error__text-image"
      />
    </div>
    <SfHeading :level="level" :title="title" :subtitle="subtitle" class="error__message" />
    <div class="error__action">
      <a :href="localizedRoute('/')">
        <SfButton class="sf-button--full-width error__action-button">
          {{ $t("Return to home") }}
        </SfButton>
      </a>
    </div>
    <div :id="productOffersNotfoundWidgetId" />
    <OSection
      :title-heading="$t('Special offers VARUS')"
      key="special-offers"
      v-if="offersNotfoundProducts.length"
      is-centered
      :background="`#F3F4F6`"
      is-not-mobile-padding
      class="o-section--slider error-special-offers"
    >
      <template #link>
        <SfLink
          :link="localizedRoute('/rasprodazha')"
          class="sf-link--primary all-products-link"
        >
          {{ $t('All goods') }}
        </SfLink>
      </template>
      <lazy-hydrate when-idle>
        <MProductCarousel
          :products="offersNotfoundProducts"
          :key="offersNotfoundProducts.length"
          arrow-color="white"
          is-white-card
        />
      </lazy-hydrate>
    </OSection>
    <OSection
      :title-heading="$t('Articles')"
      key="article"
      v-if="posts.length"
      is-centered
      is-not-mobile-padding
      class="o-section--slider error-special-offers o-section--article"
    >
      <template #link>
        <SfLink
          :link="localizedRoute('/blog')"
          class="sf-link--primary all-products-link"
        >
          {{ $t('All articles') }}
        </SfLink>
      </template>
      <lazy-hydrate when-idle>
        <MArticleCarousel404Page :posts="posts" />
      </lazy-hydrate>
    </OSection>
  </div>
</template>

<script>
import MProductCarousel from 'theme/components/molecules/m-product-carousel';
import LazyHydrate from 'vue-lazy-hydration';
import OSection from 'theme/components/organisms/o-section';
import { SfButton, SfImage, SfHeading, SfLink } from '@storefront-ui/vue';
import config from 'config';
import { mapGetters } from 'vuex';
import { eSputnikEvent } from 'theme/helpers/es';
import { getAndStoreRelatedProducts } from 'theme/helpers';
import MArticleCarousel404Page from 'theme/components/molecules/m-articel-carousel-404-page'

export default {
  name: 'MError',
  components: {
    SfButton,
    SfImage,
    SfHeading,
    SfLink,
    MProductCarousel,
    OSection,
    LazyHydrate,
    MArticleCarousel404Page
  },
  props: {
    level: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      shippingDetails: 'shipping/getShippingDetails',
      getSampleProducts: 'homepage/getSampleProducts',
      getProductRelated: 'product/getProductRelated',
      posts: 'blog/getPosts'
    }),
    sampleProducts () {
      return this.getSampleProducts
    },
    offersNotfoundProducts () {
      const products = this.getProductRelated['productOffersNotfound'] || []
      return products.length ? products : this.sampleProducts;
    },
    productOffersNotfoundWidgetId () {
      return config.esputnik?.widgetIds?.productOffersNotfound;
    }
  },
  watch: {
    'shippingDetails.shopId': {
      handler: async function (newValue) {
        if (!newValue) return

        await this.$store.dispatch('homepage/loadSampleProducts', 'specialOffers')
        this.getProductOffersNotfound()
      }
    }
  },
  created () {
    this.$store.dispatch('blog/loadBlogs');
  },
  mounted () {
    eSputnikEvent('NotFound')
    window.addEventListener('productOffersNotfoundLoaded', this.getProductOffersNotfound)
    this.$store.dispatch('homepage/loadSampleProducts', 'specialOffers')
  },
  beforeDestroy () {
    window.removeEventListener('productOffersNotfoundLoaded', this.getProductOffersNotfound)
  },
  methods: {
    getProductOffersNotfound () {
      getAndStoreRelatedProducts(
        this.productOffersNotfoundWidgetId,
        'productOffersNotfound',
        config?.entities?.product?.carouselSize,
        true
      )
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~theme/css/breakpoints';
@import "~theme/css/mixins";
@import "~theme/css/px2rem";

.error {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  &__action {
    margin-bottom: var(--spacer-50);

    @include for-desktop {
      display: flex;
      margin-bottom: var(--spacer-100);
    }

    ::v-deep {
      button {
        width: px2rem(157);
      }
    }
  }
  &__action-button {
    color: var(--white);
    flex: 1;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding: var(--spacer-16) var(--spacer-27);

    &--secondary {
      margin-top: var(--spacer-xl);

      @include for-desktop {
        margin: auto var(--spacer-2xl);
      }
    }
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    margin: var(--spacer-82) auto var(--spacer-68);
    min-height: 165px;
    position: relative;
    width: 100%;

    @media (min-width: $tablet-min) {
      min-height: px2rem(261);
    }
  }

  .error__text-image {
    margin: 0 var(--spacer-26);
  }

  .error-special-offers {
    width: 100%;
    margin-bottom: var(--spacer-80);

    @include only-mobile {
      margin-bottom: 50px;
    }

    .sf-carousel {
      position: relative;
    }

    ::v-deep .sf-carousel__controls {
      display: none;
    }

    @include for-desktop {
      ::v-deep .sf-carousel__controls {
        display: flex;
        transform: var(--carousel-controls-transform, translate3d(0, -50%, 0));
      }
    }
  }

  ::v-deep .o-section {
    &--center {
      padding: 0;
    }

    &__heading {
      padding-left: var(--spacer-10);
      @include for-mobile {
        padding-left: var(--spacer-10);
        padding-right: var(--spacer-10);
      }
    }

    &__content {
      padding-right: 0;
      padding-left: 0;

      .sf-carousel {
        &__wrapper {
          @include for-desktop {
            margin-bottom: var(--spacer-10);
            max-width: unset;
            width: unset;
          }
        }

        .glide__slide {
          flex-shrink: initial;

          .sf-product-card {
            max-width: unset;

            &__title {
              margin-top: 10px;
              font-weight: normal;
            }
            .sf-product-card__image-wrapper {
              padding: var(--spacer-10);
            }
          }
        }
      }
    }

    &--article {
      padding-top: 0;
      .sf-carousel__wrapper {
        padding-inline: var(--spacer-10);
      }
    }
  }

}

::v-deep {
  .error__message {
    .sf-heading__title {
      @include header-title;
      text-align: center;
    }

    .sf-heading__subtitle {
      color: var(--dark-gray);
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      max-width: 650px;
      margin: var(--spacer-16) var(--spacer-20);

      @media (max-width: $mobile-max) {
        margin: var(--spacer-16) var(--spacer-10);
      }
    }
  }

  .error__image {
    & > img {
      height: 100%;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
    }
  }
}
</style>
